@import '@immosolve/empro-shared-ui/styles.scss';

@layer base {
  img {
    @apply inline-block;
  }
}

html {
  @apply h-full;
}

select,
option {
  font-family: Inter, Arial, sans-serif;
}

.wrapper {
  max-width: initial !important;
}

a {
  @apply cursor-pointer;
}
